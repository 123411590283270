
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.icon-spin {
  -webkit-animation: icon-spin 2s infinite linear;
  animation: icon-spin 2s infinite linear;
}

html {
  font-size: 16px;
}

html,
body {
  position: fixed;
  height: 100%;
  width: 100%;
}

#modal-root,
#error-modal {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 999;
  display: flex;
  align-items: stretch;
  justify-content: center;
}

.modal-container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  width: 100vw;
  height: 100%;
  justify-content: center;
  align-items: stretch;
}

input.visit-input {
  font-family: 'Inter', sans-serif;
  font-size: 1.1rem;
  border: none;
}

input.visit-input:focus {
  outline: none;
}

input.consultation-input {
  font-family: 'Inter', sans-serif;
  font-size: 11px;
  border: none;
}

input.consultation-input:focus {
  outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

body {
  font-family: 'Inter', sans-serif;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.ahc-header {
  font-family: 'Inter', sans-serif !important;
  text-transform: none !important;
  }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  display: flex;
  overflow: hidden;
  flex-direction: column;
  max-width: 800px;
  height: 100%;
  margin: 0 auto;
}

.fade-enter {
  opacity: 0;
}

.fade-enter.fade-enter-active {
  transition: 300ms linear;
  opacity: 1 !important;
}

.fade-enter-done {
  opacity: 1 !important;
}

.fade-exit {
  opacity: 1 !important;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: 3000ms linear;
}

.fade-in-enter,
.fade-in-appear {
  transform: translateY(2rem);
  opacity: 0;
}

.fade-in-enter.fade-in-enter-active,
.fade-in-appear.fade-in-appear-active {
  transition: 200ms linear;
  transform: none;
  opacity: 1;
}

button:focus{
  outline: none;
}

.slide-next-enter {
  transform: translateX(100%);
}

.slide-next-enter.slide-next-enter-active {
  transition: 200ms linear;
  transform: unset;
}

.slide-next-exit {
  transform: translateY(-100%);
}

.slide-next-exit.slide-next-exit-active {
  transition: 200ms linear;
  transform: translate(100%, -100%);
}

.slide-previous-enter {
  transform: scale(0.9);
}

.slide-previous-enter.slide-previous-enter-active {
  transition: 200ms linear;
  transform: unset;
}

.slide-previous-exit {
  transform: translateX(-100%);
}

.slide-previous-exit.slide-previous-exit-active {
  transition: 200ms linear;
  transform: translateX(-100%) scale(0.6);
}

.fly-in-enter,
.fly-in-appear {
  transform: translateY(100%);
}

.fly-in-enter.fly-in-enter-active,
.fly-in-appear.fly-in-appear-active {
  transition: 200ms linear;
  transform: none;
}

.fly-in-exit {
  transform: none;
}

.fly-in-exit.fly-in-exit-active {
  transition: 200ms linear;
  transform: translateY(100%);
}

.slide-in-enter,
.slide-in-appear {
  transform: translateX(100%);
}

.slide-in-enter.slide-in-enter-active,
.slide-in-appear.slide-in-appear-active {
  transition: 200ms linear;
  transform: none;
}

.slide-in-exit {
  transform: none;
}

.slide-in-exit.slide-in-exit-active {
  transition: 200ms linear;
  transform: translateX(100%);
}
/*
.expand-hide-enter.expand-hide-enter-active {
  padding: 0 !important;
  height: 0;
  transition: 200ms linear;
}

.expand-hide-enter-done {
  padding: 0 !important;
  height: 0;
}

.expand-enter.expand-enter-active {
  background: white;
  transition: 200ms linear;
  margin-top: 0;
  /* transform: translateY(-100%); */
/*}

.expand-enter-done {
  background: white;
  margin-top: 0;
  transform: translateY(-100%);
}*/


.OT_publisher {
  width: 264px !important;
  height: 198px !important;
  position: absolute !important;
  z-index: 990 !important;
  bottom: 0 !important;
  right: 0 !important;
}

.OT_subscriber {
  position: unset !important;
}
.w-full{
  max-width: 800px;
}


.expand-enter {
  height: 0px !important;
  overflow: hidden;
}

.expand-enter-active {
  height: 140px !important;
  transition: height .3s ease;
}

.expand-exit-active {
  height: 0px !important;
  transition: height .3s ease;
  overflow: hidden;
}

.expand-exit {
  height: 0px !important;
  transition: height .3s ease;
  overflow: hidden;
}

.clues {
  @apply font-medium text-body-s text-txtsecondary
}
